.wrapper {
    width: 100%;
    @include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0));
    will-change: auto;
    @include transition(padding .15s);
  }

.header-shadow{
  -webkit-box-shadow: 0px -5px 5px -5px rgba(34, 60, 80, 0.6) inset;
  -moz-box-shadow: 0px -5px 5px -5px rgba(34, 60, 80, 0.6) inset;
  box-shadow: 0px -5px 5px -5px rgba(34, 60, 80, 0.6) inset;
} 